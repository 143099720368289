import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menuTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.displayTime,"transition":"scale-transition","offset-y":"","max-width":_vm.maxWidth,"min-width":_vm.minWidth},on:{"update:returnValue":function($event){_vm.displayTime=$event},"update:return-value":function($event){_vm.displayTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":_vm.prependIcon,"append-icon":_vm.appendIcon,"rules":_vm.rules,"readonly":"","outlined":_vm.outlined,"disabled":_vm.disabled,"height":_vm.height,"dark":_vm.dark,"hint":_vm.hint,"persistent-hint":_vm.persistentHint},model:{value:(_vm.displayTime),callback:function ($$v) {_vm.displayTime=$$v},expression:"displayTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.menu)?_c(VTimePicker,{attrs:{"full-width":"","format":"24hr","no-title":"","min":_vm.computedMinTime,"max":_vm.computedMaxTime,"disabled":_vm.disabled,"allowed-hours":_vm.allowedHours,"allowed-minutes":_vm.allowedMinutes},on:{"click:minute":[function($event){return _vm.$refs.menuTime.save(_vm.displayTime)},_vm.emitMinuteSelectionEvent],"click:hour":_vm.emitHourSelectionEvent},model:{value:(_vm.displayTime),callback:function ($$v) {_vm.displayTime=$$v},expression:"displayTime"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }