<template>
     <v-menu
        ref="menuTime"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="displayTime"
        transition="scale-transition"
        offset-y
        :max-width="maxWidth"
        :min-width="minWidth"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="displayTime"
            :label="label"
            :prepend-icon="prependIcon"
            :append-icon="appendIcon"
            :rules="rules"
            readonly
            v-bind="attrs"
            v-on="on"
            :outlined="outlined"
            :disabled="disabled"
            :height="height"
            :dark="dark"
            :hint="hint"
            :persistent-hint="persistentHint"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="menu"
            v-model="displayTime"
            full-width
            format="24hr"
            no-title
            @click:minute="$refs.menuTime.save(displayTime)"
            :min="computedMinTime"
            :max="computedMaxTime"
            :disabled="disabled"
            v-on:click:hour="emitHourSelectionEvent"
            v-on:click:minute="emitMinuteSelectionEvent"
            :allowed-hours="allowedHours"
            :allowed-minutes="allowedMinutes"
        ></v-time-picker>
    </v-menu>
</template>
<script>

import { DateTime } from 'luxon';

const baseDateTime = DateTime.local(1970, 1, 1, 0, 0, 0);

export default {
    data: function() {
        return {
            menu: false,
        };
    },
    props: {
        rules: {
            type: Array,
            default() {
                return [];
            }
        },
        label: {
            type: String,
            default: ''
        },
        maxWidth: {
            type: String,
            default: '290px'
        },
        minWidth: {
            type: String,
            default: '290px'
        },
        height: {
            type: String,
            default: undefined
        },
        prependIcon: String,
        appendIcon: String,
        valueTime: {
            type: String,
            default: null
        },
        min: String, 
        max: String,
        disabled: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            default: undefined
        },
        persistentHint: {
            type: Boolean,
            default: false
        },
        allowedHours: {
            type: Function,
            default: (val) => true
        },
        allowedMinutes: {
            type: Function,
            default: (val) => true
        }
    },
    methods: {
        emitHourSelectionEvent: function(args) {
            this.$emit('selected-hour', args);
        },
        emitMinuteSelectionEvent: function(args) {
            this.$emit('selected-minute', args);
        },
    },
    computed: {
        displayTime: {
            get: function() {
                let parsedValue = DateTime.fromISO(this.valueTime)
                if (parsedValue.invalid == null) {
                    return parsedValue.toLocaleString(DateTime.TIME_24_SIMPLE);
                }
                return null
            },
            set: function(newValue) {
                if (newValue) {
                    let timeArr = newValue.split(':');
                    let hours = timeArr[0];
                    let minutes = timeArr[1];
                    this.$emit('update:valueTime', baseDateTime.plus({hours: hours, minutes: minutes}).toISO())
                }
                else {
                    this.$emit('update:valueTime', null)
                }
            }
        },
        computedMinTime() {
            if(this.min) {
                let parsedTime = DateTime.fromISO(this.min);
                if(parsedTime.invalid == null) {
                    return parsedTime.toLocaleString(DateTime.TIME_24_SIMPLE)
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        computedMaxTime() {
            if(this.max) {
                let parsedTime = DateTime.fromISO(this.max);
                if(parsedTime.invalid == null) {
                    return parsedTime.toLocaleString(DateTime.TIME_24_SIMPLE)
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }
}
</script>
